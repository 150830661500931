import React from "react";
import Seo from "../../components/SEO/SEO";
import Layout from "../../components/Layout/Layout";
import {
  Box,
  Button,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { useStyles } from "../../index.styles";
import TinTuc from "../../components/TinTucMoiNhat/TinTuc";
import StopIcon from "@material-ui/icons/Stop";
import { useStaticQuery, graphql, Link } from "gatsby";
import ButtonBackToHome from "../../components/Button/ButtonBackToHome";

const NewsPage = () => {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1000px)");

  const listPosts = useStaticQuery(graphql`
    query listPosts {
      allWpPost(sort: { fields: date, order: DESC }) {
        edges {
          node {
            content
            id
            title
            date
            featuredImage {
              node {
                localFile {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo title="Tin tức" />
      <Box bgcolor="#f5f5f5">
        <Box
          width={breakpointsXs ? "90%" : "80%"}
          margin="auto"
          py={breakpointsXs ? 3 : 8}
        >
          <Box
            display={breakpointsXs ? undefined : "flex"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">
              <b>TIN TỨC MỚI NHẤT</b>
            </Typography>
            <Box my={2}>
              <ButtonBackToHome />
            </Box>
          </Box>
          <Box mt={3}>
            {listPosts?.allWpPost?.edges?.map((item, index) => {
              return (
                index < 3 && (
                  <Box display="flex" mt={0.5}>
                    <Box color="#308632" mr={0.5}>
                      <StopIcon fontSize="small" />
                    </Box>
                    <Box className={classes.titleNew}>
                      <Link to={`/tin-tuc/${item.node.id}`}>
                        <Box fontSize={20}>{item?.node?.title}</Box>
                      </Link>
                    </Box>
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      </Box>

      <Box
        width={breakpointsXs ? "90%" : "80%"}
        margin="auto"
        py={breakpointsXs ? 3 : 8}
      >
        <Box mb={5} fontSize={34} fontWeight={700}>
          Tất cả tin tức
        </Box>
        <Box display={breakpointsXs ? undefined : "flex"} flexWrap="wrap">
          {listPosts?.allWpPost?.edges?.map(item => (
            <Box className={classes.news} mb={4} mx={1.25}>
              <Box className={classes.heighImage} overflow="hidden" mb={2}>
                <img
                  className="image"
                  src={item.node.featuredImage?.node?.localFile.url}
                  alt=""
                />
              </Box>
              <TinTuc date={item.node.date} title={item.node.title} />
              <Button
                component={Link}
                to={`/tin-tuc/${item.node.id}`}
                className={classes.buttonViewDetail}
              >
                <b style={{ color: "white" }}>XEM CHI TIẾT</b>
                <Box ml={1.5}>
                  <FontAwesomeIcon icon={faLongArrowAltRight} />
                </Box>
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider />
    </Layout>
  );
};

export default NewsPage;
