import React from "react";
import { Box, Button, makeStyles } from "@material-ui/core";
import {
  faLongArrowAltRight,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "gatsby";
const useStyles = makeStyles(() => ({
  buttonViewDetail: {
    backgroundColor: "black",
    color: "white",
    height: "46px",
    padding: "0px 30px",
    "&:hover": {
      backgroundColor: "#308632",
    },
  },
}));

const ButtonBackToHome = ({ isBack = false }) => {
  return (
    <Button
      onClick={() => {
        isBack ? navigate("/tin-tuc") : navigate("/");
      }}
      className={useStyles().buttonViewDetail}
    >
      {isBack ? (
        <Box color="white" display="flex" alignItems="center">
          <Box mr={1.5}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
          </Box>
          <b>TRỞ VỀ</b>
        </Box>
      ) : (
        <Box color="white" display="flex" alignItems="center">
          <b>TRỞ VỀ TRANG CHỦ</b>
          <Box ml={1.5}>
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </Box>
        </Box>
      )}
    </Button>
  );
};

export default ButtonBackToHome;
